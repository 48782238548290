export default [
  {
    path: '/',
    name: 'landing',
    component: () => import('../pages/Landing.vue'),
  },
  {
    path: '/mentions-legales',
    name: 'legalNotice',
    component: () => import('../pages/LegalNotice.vue'),
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('../pages/NotFound.vue'),
  }
];